import React from 'react';
import { Link } from 'gatsby';

import { useSanityPath } from '@hooks/useSanityPath';
import { SanityImage } from '@components/sanity/sanityImage';

export const PromoCard = ({ image, subtitle, title, page }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} className="relative rounded-xl overflow-hidden">
      <div className="absolute z-10 text-white p-4 bottom-4">
        <h4 className="subThree">{subtitle}</h4>
        <h2 className="text-xl md:text-2xl">{title}</h2>
      </div>
      <div className="aspect-w-5 aspect-h-3 relative w-full bg-black lg:aspect-none lg:h-full lg:absolute">
        <SanityImage
          image={image}
          className="absolute h-full w-full object-cover opacity-60 lg:absolute lg:w-full lg:h-full"
        />
      </div>
    </Link>
  );
};
